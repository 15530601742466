import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import { CHINA_VERSION } from '../../globals';
import useAxios from '../../hooks/useAxios/useAxios';
import useDetermineSelectRoomSearchParameters from '../../hooks/useDetermineSelectRoomSearchParameters/useDetermineSelectRoomSearchParameters';
import { setCurrentCurrency } from '../../redux/slices/appSettingsSlice/appSettingsSlice';
import { resetBookingsState } from '../../redux/slices/bookingsSlice/bookingsSlice';
import { resetGuestFormState } from '../../redux/slices/guestFormSlice/guestFormSlice';
import { resetPaymentState } from '../../redux/slices/paymentSlice/paymentSlice';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { useValidateSelectRoomParams } from './hooks';

const SingleHotelViewWrapper = () => {
  const axios = useAxios();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { scriptsLoaded, metadataLoaded } = useOutletContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const hotels = useSelector((state) => state.belmond.hotels);
  const bookingProductCode = useSelector((state) =>
    state.bookings.list.length ? state.bookings.list[0].productCode : null
  );
  const currencySelectedByUser = useSelector(
    (state) => state.appSettings.currencies.selectedByUser
  );

  const productCode = useRef(
    searchParams.get('productCode') || bookingProductCode
  );

  const hotel =
    useSelector((state) => state.belmond.hotels[productCode.current]) || {};

  const { defaultValues, validateSpecialCode, validateRateCode } =
    useDetermineSelectRoomSearchParameters(hotel);

  // perhaps move this somewhere else
  // so we dont need to run it for all views in the single hotel view wrapper
  const { allParamsValidated } = useValidateSelectRoomParams(
    hotel,
    defaultValues,
    validateSpecialCode,
    validateRateCode,
    axios
  );

  // Set default currency if user hasn't selected one
  useEffect(() => {
    if (!currencySelectedByUser) {
      let currencyToUse = CHINA_VERSION ? 'CNY' : hotel?.currency || 'GBP';
      dispatch(setCurrentCurrency(currencyToUse));
    }
  }, [currencySelectedByUser, dispatch, hotel?.currency]);

  // Check for maintenance mode
  const checkMaintenance = useCallback(async () => {
    const { maintenance } = hotels[productCode.current] || {};
    if (!maintenance) return;

    if (pathname !== `/maintenance`) {
      navigate(`/maintenance?productCode=${productCode.current}`);
    }
  }, [hotels, navigate, pathname, productCode]);

  const confirmationStateReset = useCallback(() => {
    if (pathname === '/confirmation') {
      dispatch(resetGuestFormState());
      dispatch(resetBookingsState());
      dispatch(resetPaymentState());
    }
  }, [dispatch, pathname]);

  // checks when location changes
  useEffect(() => {
    checkMaintenance();
    confirmationStateReset();
  }, [
    navigate,
    pathname,
    hotels,
    productCode,
    checkMaintenance,
    confirmationStateReset,
  ]);

  // Redirect to home if no product code
  useEffect(() => {
    if (!productCode.current) {
      navigate('/');
    }
  }, [navigate]);

  const outletReady = scriptsLoaded && metadataLoaded && allParamsValidated;

  return (
    <div data-testid="single-hotel-view">
      <Header
        phone={hotel?.phone}
        hotelCurrency={hotel?.currency}
        productCode={productCode.current}
      />

      {outletReady && <Outlet context={{ hotel }} />}
      {!outletReady && <ViewLoader />}

      <Footer
        loading={!outletReady}
        instagramLink={hotel?.metadata?.instagramLink}
        facebookLink={hotel?.metadata?.facebookLink}
        address={`${hotel?.address?.address}, ${hotel?.address?.city}, ${hotel?.address?.postalCode} `}
        phone={hotel?.phone}
        productCode={productCode.current}
        contactUsLink={hotel?.metadata?.contactUsLink}
        findUsLink={hotel?.metadata?.findUsLink}
      />
    </div>
  );
};

export default SingleHotelViewWrapper;
